<template>
  <div class="container">
    <div class="page-content">
      <div class="channel-panel">
        <table>
          <thead>
            <tr>
              <th colspan="2">ช่องทางการเชื่อมต่อ</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="items of marketList" :key="items.id">
              <td width="65">
                <img :src="items.marketplaceUrl" alt="Marketplace icon" />
              </td>
              <td width="40%">
                <div>{{ items.marketplace }}</div>
                <div class="f-sm">{{ items.shopName }}</div>
              </td>
              <td class="content-between h-100">
                <span style="color: green">เชื่อมต่อสมบูรณ์</span>

                <b-dropdown
                  class="connect-btn"
                  no-caret
                  variant="link"
                  toggle-class="text-decoration-none"
                  right
                  v-if="items.marketplace != 'CRM'"
                >
                  <template #button-content>
                    <font-awesome-icon
                      :icon="['fas', 'ellipsis-v']"
                      style="color: black"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="items.status == 0"
                    @click="connectMarketPlace(1, items.branchId)"
                    >เชื่อมต่อ</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="items.status == 1"
                    @click="connectMarketPlace(0, items.branchId)"
                    >ลบการเชื่อมต่อ</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-panel">
        <b-button
          class="w-100"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          @click="$router.push('/connectmarketplace')"
          >เพิ่มช่องทางเชื่อมต่อ</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marketList: []
    };
  },
  computed: {
    addNewValue() {
      return Number(this.$route.query.q || 0);
    }
  },
  created() {
    this.getMarketPlace();
  },
  methods: {
    async getMarketPlace() {
      const data = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/User/get/marketplace`
      );
      this.marketList = data.detail.connect_marketplace;
    },
    async connectMarketPlace(type, id) {
      this.$swal({
        title: "คุณต้องการลบการเชื่อมต่อนี้ หรือไม่ ?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(async willDelete => {
        if (willDelete) {
          const res = await this.$axios.delete(
            `${process.env.VUE_APP_API}/api/v1/User/delete/marketplace/${id}`
          );
          if (res.result == 1) {
            this.$swal("สำเร็จ !", "", "success");
            this.getMarketPlace();
          } else {
            this.$swal("เกิดข้อผิดพลาด !", res.message, "error");
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;
  height: 100%;
  img {
    width: 50px;
    height: 50px;
    // border-radius: 50%;
  }
  td {
    padding: 5px 0px;
  }
}
.button-panel {
  position: absolute;
  width: calc(100% - 2rem);
  left: 0;
  margin: 1rem;
  bottom: 0;
}
.channel-panel {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

::v-deep .connect-btn button {
  background-color: unset;
}
::v-deep .dropdown-toggle::after {
  display: none;
}
</style>
